@media (min-width: 1000px) {
  html {
    scroll-behavior: smooth;
  }
}

html {
  background-color: #f29699;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Merch Carousel */

.react-multi-carousel-list {
  position: absolute !important;
  height: 100%;
  top: 0;
  left: 0;
  width: inherit;
}

.react-multiple-carousel__arrow {
  background: #f29699 !important;
  transition: background 200ms !important;
}

.react-multiple-carousel__arrow::before {
  color: white !important;
}

.react-multiple-carousel__arrow:hover {
  background: #f9e3e4 !important;
}

.react-multiple-carousel__arrow:hover::before {
  color: black !important;
}

.react-multi-carousel-dot-list {
  bottom: 10px !important;
}

.react-multi-carousel-dot button {
  border-color: transparent !important;
  background-color: #f8e3e3 !important;
}

.react-multi-carousel-dot--active button {
  background: #f29699 !important;
}
